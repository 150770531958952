export enum Directories {
  Images = "../img",
  Videos = "../videos",
}

export interface IDirectory {
  name: Directories;
}

export const defaultDirectory: IDirectory = {
  name: Directories.Videos,
};
