import { useThree } from "@react-three/fiber";
import { useEffect } from "react";

export const InvalidateFallback = () => {
  const { invalidate } = useThree();
  invalidate();
  return <></>;
};

export const AdaptivePixelRatio = () => {
  const current = useThree((state) => state.performance.current);
  const setPixelRatio = useThree((state) => state.setDpr);
  useEffect(() => {
    setPixelRatio(window.devicePixelRatio * current);
  }, [current, setPixelRatio]);
  return <></>;
};
