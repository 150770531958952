import { ThreeEvent, useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { gsap } from "gsap";
import {
  Color,
  DoubleSide,
  Group,
  Intersection,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  ShaderMaterial,
  Vector2,
} from "three";
import { useThree } from "@react-three/fiber";
import { defaultDirectory, Directories, IImage, IMetaData } from "Interfaces";
import { useVideo, useImage, useTexture } from "Hooks";
import vertexShader from "shaders/vertexShader";
import fragmentShader from "shaders/fragmentShader";
import { NormalText } from "./NormalText";
import { getFileName } from "Helpers/Helpers";
import useTouchEvents from "Hooks/useTouchEvents";
import { isMobile } from "react-device-detect";
import { GradientTexture } from "@react-three/drei";

const RAYCAST_LAYER = 1;

type Props = {
  id: number;
  metaData: IMetaData;
};

type Events = {
  onTouchEnd: (e: any) => void;
  onClick: (e: any) => void;
};

const nsfwColor = new Color("red");
const PLANE_SIZE = 2.0;

export default function ImagePlane({ id, metaData, localUri }: Props & IImage) {
  const groupRef = useRef<Group>(null);
  const meshRef = useRef<Mesh>(null);
  meshRef.current?.layers.enable(RAYCAST_LAYER);
  const [isHovered, setIsHovered] = useState(false); // Hover state

  const handlePointerClick = (
    e: ThreeEvent<MouseEvent> | ThreeEvent<TouchEvent>
  ) => {
    e.stopPropagation();

    if (!metaData.url || !isHovered) return;
    window.open(metaData.url, "_blank");
  };

  const { touchPosition } = useTouchEvents();
  const image = useImage(localUri);
  const texture = useTexture(localUri);
  const video = useVideo(localUri);

  const imgWidth = image[0]!.width;
  const imgHeight = image[0]!.height;

  const map =
    defaultDirectory.name === Directories.Videos ? video[0]! : texture[0]!;
  const fileName = getFileName(localUri);

  const { raycaster, invalidate } = useThree();
  raycaster.layers.set(RAYCAST_LAYER);

  const speed = {
    value: 0.006,
  };

  var mod = id % 2 === 0 ? -1 : 1;

  let a = 0;
  let da = 0.05;

  let rand = MathUtils.clamp(0.1 + Math.random(), 0.025, 0.035) * mod;

  const dimmingFactor = isHovered ? 0.1 : 1;

  const uniforms = useMemo(
    () => ({
      uColor: { value: new Color("#FFFFFF") },
      uOuterColor: { value: new Color("#FFFFFF") },
      uPlaneSize: { value: new Vector2(PLANE_SIZE, PLANE_SIZE) },
      uImageSize: { value: new Vector2(imgWidth, imgHeight) },
      uMousePos: { value: new Vector2(0.0, 0.0) },
      uMouseRadius: { value: 0.0 },
      uTime: { value: 0.0 },
      uRadius: { value: 0.25 },
      uTexture: { value: map },
      uSpikes: { value: 6 }, // adjust the waviness
      uDimmingFactor: { value: dimmingFactor },
    }),
    [imgWidth, imgHeight, map, PLANE_SIZE]
  );

  useEffect(() => {
    uniforms.uDimmingFactor.value = dimmingFactor;
  }, [dimmingFactor, uniforms]);

  useFrame(({ camera }) => {
    if (!groupRef.current || !meshRef.current) return;

    uniforms.uTime.value += speed.value;

    a += da;

    meshRef.current.position.x =
      groupRef.current.position.x + rand * Math.sin(a);
    meshRef.current.position.y =
      groupRef.current.position.y + rand * Math.cos(a);

    raycaster.setFromCamera(touchPosition, camera);
    const intersects = raycaster.intersectObject(meshRef.current);
    const [firstIntersect] = intersects || [];
    const { uv: point } = firstIntersect || {};

    if (point) {
      setIsHovered(true);
      uniforms.uMousePos.value = point;
      gsap.to(uniforms.uMouseRadius, {
        value: 0.05,
        duration: 0.4,
        overwrite: true,
      });

      gsap.to(uniforms.uRadius, {
        value: 0.55,
        duration: 0.5,
        overwrite: true,
        onStart: () => {
          invalidate();
        },
        onComplete: () => {
          invalidate();
        },
      });
      gsap.to(speed, {
        value: 0.02,
        duration: 0.5,
        overwrite: true,
      });
      gsap.to(uniforms.uSpikes, {
        value: 2.5,
        duration: 0.8,
        overwrite: true,
      });
    } else {
      setIsHovered(false);
      uniforms.uMouseRadius.value = 0;
      gsap.to(uniforms.uRadius, {
        value: 0.25,
        duration: 0.25,
        overwrite: true,
        onStart: () => {
          invalidate();
        },
        onComplete: () => {
          invalidate();
        },
      });
      gsap.to(speed, {
        value: 0.006,
        duration: 1.8,
        overwrite: true,
      });
      gsap.to(uniforms.uSpikes, {
        value: 6,
        duration: 2,
        overwrite: true,
      });
      gsap.to(uniforms.uMouseRadius, {
        value: 0.0,
        duration: 0.2,
        overwrite: true,
      });
    }
  });

  return (
    <group ref={groupRef}>
      <mesh ref={meshRef} onPointerUp={handlePointerClick}>
        {metaData.isNSFW?.valueOf() === true && (
          <NormalText
            text={"NSFW"}
            color={nsfwColor}
            fontSize={0.2}
            position={[-0.5, isMobile && isHovered ? 1.25 : 0.25, 0.1]}
          />
        )}
        <planeGeometry args={[PLANE_SIZE, PLANE_SIZE, 1, 1]} />
        <Suspense fallback={<FallbackText />}>
          {isHovered && (
            <NormalText
              text={fileName ?? "Test"}
              fontSize={0.25}
              position={[0, isMobile ? 1 : 0, 0.1]}
            />
          )}
        </Suspense>
        <Suspense fallback={<FallbackMaterial />}>
          <shaderMaterial
            toneMapped={false}
            uniforms={uniforms}
            uniformsNeedUpdate={true}
            transparent={true}
            depthWrite={false}
            alphaTest={0.5}
            vertexShader={vertexShader}
            fragmentShader={fragmentShader}
            side={DoubleSide}
          />
        </Suspense>
      </mesh>
    </group>
  );
}

const FallbackMaterial = () => {
  return (
    <meshBasicMaterial>
      <GradientTexture stops={[0, 1]} colors={["#CC00FF", "blue"]} />
    </meshBasicMaterial>
  );
};

const FallbackText = () => {
  return (
    <NormalText text={"Loading..."} fontSize={0.15} position={[0, 0, 0.1]} />
  );
};
