import { Vector3 } from "three";

export function getExtension(filename: string) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

export function getFileName(filename: string) {
  const startString = "/media/";
  const endString = ".";

  const startIndex = filename.indexOf(startString);
  const endIndex = filename.indexOf(endString, startIndex + startString.length);

  if (startIndex !== -1 && endIndex !== -1) {
    const value = filename.substring(startIndex + startString.length, endIndex);
    return value;
  } else {
    console.log("No match found.");
    return "Default Text";
  }
}

export const areVectorsClose = (
  vector1: Vector3,
  vector2: Vector3,
  threshold: number
) => {
  const diffX = Math.abs(vector1.x - vector2.x);
  const diffY = Math.abs(vector1.y - vector2.y);
  const diffZ = Math.abs(vector1.z - vector2.z);

  return diffX <= threshold && diffY <= threshold && diffZ <= threshold;
};

export const randomRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};
