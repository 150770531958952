import { Text } from "@react-three/drei";
import { defaultText, IText } from "Interfaces";
import { Color } from "three";

export function NormalText({ ...props }, textProps: IText = defaultText) {
  if (Object.keys(textProps!)?.length === 0) {
    textProps = defaultText;
  }

  const color = props.color ?? new Color("white");

  return (
    <Text {...textProps} {...props}>
      <meshBasicMaterial toneMapped={false} color={color} />
    </Text>
  );
}
