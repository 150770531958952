import { DEFAULT_IMAGE } from "Constants";
import { IOptional } from "Interfaces";

type DefaultImageValues = Pick<IImage, "localUri">;
type ImageWithDefaultsOptional = IOptional<IImage, keyof DefaultImageValues>;

export interface IImage {
  localUri: string;
}

const defaultImageValues: DefaultImageValues = {
  localUri: DEFAULT_IMAGE,
};

const newImage: ImageWithDefaultsOptional = {};

export const defaultImage: IImage = {
  ...defaultImageValues,
  ...newImage,
};
