import { useVideoTexture } from "@react-three/drei";
import { DEFAULT_VIDEO } from "Constants/GlobalConstant";
import { getExtension } from "Helpers/Helpers";
import { defaultVideo, IVideo } from "Interfaces";
import { VideoTexture } from "three";

const filePath = require.context("../videos", false);

export function useVideo(uri: string, props?: IVideo) {
  if (!props) props = defaultVideo;
  const input = getValidInput(uri);
  const video = useVideoTexture(input, props!);
  return [video as VideoTexture];
}

function getValidInput(uri: string) {
  if (!isVideo(uri)) return filePath(DEFAULT_VIDEO);
  const isStatic = uri.split("/")[1] === "static";
  const input = !isStatic ? filePath(uri) : uri;
  return input;
}

// Source: https://stackoverflow.com/a/7977314
function isVideo(filename: string) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      // etc
      return true;
  }
  return false;
}
