import { defaultDirectory, IDirectory } from "Interfaces";

const images = require.context("../img");
const videos = require.context("../videos");
export function useDirectory(directory: IDirectory = defaultDirectory) {
  const files: __WebpackModuleApi.RequireContext =
    directory.name === "../img" ? images : videos;
  const cache = importAll(files);
  return cache;
}

function importAll(r: __WebpackModuleApi.RequireContext) {
  const cache: any = {};
  r.keys().forEach((key: string, id: number) => (cache[id] = r(key)));
  return cache;
}
