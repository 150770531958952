import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { ResizeObserver } from "@juggle/resize-observer";
import "./styles.css";
import { MutatingDots } from "react-loader-spinner";
import React from "react";
import { AdaptivePixelRatio } from "Components";
import { isMobile } from "react-device-detect";

const Scene = React.lazy(() => import("./Scene"));

function Fallback() {
  return (
    <div className="container">
      <div className="centered-div">
        <h1>Loading...</h1>
        <MutatingDots
          height="100"
          width="100"
          color="#AA77AA"
          secondaryColor="#1e0144"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          visible={true}
          wrapperClass="dots"
        />
      </div>
    </div>
  );
}

function App() {
  return (
    <Suspense fallback={<Fallback />}>
      <Canvas
        frameloop={isMobile ? "demand" : "always"}
        gl={{
          antialias: false,
          powerPreference: "low-power",
        }}
        dpr={Math.max(window.devicePixelRatio, 2)}
        resize={{ polyfill: ResizeObserver }} // Allows @react-spring/three to work in Safari
      >
        <AdaptivePixelRatio />
        <Scene />
      </Canvas>
    </Suspense>
  );
}

export { App as default };
