import { DEFAULT_FONT_TYPE } from "Constants/GlobalConstant";
import { IOptional } from "Interfaces";

type DefaultTextValues = Pick<IText, "text">;
type TextWithDefaultsOptional = IOptional<IText, keyof DefaultTextValues>;

export interface IText {
  text: string;
  font?: string;
  fontSize?: number;
  strokeColor?: string;
  strokeWidth?: number;
  letterSpacing?: number | -0.06;
  center?: boolean | true;
  textAlign?: "center";
  style?: { position: string };
}

const defaultTextValues: DefaultTextValues = {
  text: "Test",
};

const newText: TextWithDefaultsOptional = {
  font: DEFAULT_FONT_TYPE,
  fontSize: 1,
  strokeColor: "#6902f1",
  strokeWidth: 0.001,
  letterSpacing: -0.06,
  center: true,
  textAlign: "center",
  style: { position: "relative" },
};

export const defaultText: IText = {
  ...defaultTextValues,
  ...newText,
};
