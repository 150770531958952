import { Vector3 } from "three";
import { IOptional } from "Interfaces";

const DEFAULT_START_RADIUS = 2.5;
const DEFAULT_GROWTH_RATE = 0.05;
const DEFAULT_ANGLE_INCREMENT = -Math.PI / 5.5;

type DefaultSpiralValues = Pick<ISpiral, "startRadius"> &
  Pick<ISpiral, "growthRate"> &
  Pick<ISpiral, "angleIncrement"> &
  Pick<ISpiral, "center">;
type SpiralWithDefaultsOptional = IOptional<ISpiral, keyof DefaultSpiralValues>;

export interface ISpiral {
  startRadius: number;
  growthRate: number;
  angleIncrement: number;
  center: Vector3;
}

const defaultSpiralValues: DefaultSpiralValues = {
  startRadius: DEFAULT_START_RADIUS,
  growthRate: DEFAULT_GROWTH_RATE,
  angleIncrement: DEFAULT_ANGLE_INCREMENT,
  center: new Vector3(0, 0, -1),
};

const newSpiral: SpiralWithDefaultsOptional = {};

export const defaultSpiral: ISpiral = {
  ...defaultSpiralValues,
  ...newSpiral,
};
