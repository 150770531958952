import { IOptional } from "Interfaces";

type DefaultVideoValues = Pick<IVideo, "unsuspend"> &
  Pick<IVideo, "muted"> &
  Pick<IVideo, "loop"> &
  Pick<IVideo, "start"> &
  Pick<IVideo, "crossOrigin">;

type VideoWithDefaultsOptional = IOptional<IVideo, keyof DefaultVideoValues>;

export interface IVideo {
  unsuspend: "canplay" | "canplaythrough" | "loadstart" | "loadedmetadata";
  muted: boolean;
  loop: boolean;
  start: boolean;
  crossOrigin: string;
}

const defaultVideoValues: DefaultVideoValues = {
  unsuspend: "canplaythrough",
  muted: true,
  loop: true,
  start: true,
  crossOrigin: "Anonymous",
};

const newVideo: VideoWithDefaultsOptional = {};

export const defaultVideo: IVideo = {
  ...defaultVideoValues,
  ...newVideo,
};
