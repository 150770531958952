import { useLoader } from "@react-three/fiber";
import { DEFAULT_IMAGE } from "Constants";
import { getExtension } from "Helpers/Helpers";
import { Texture } from "three";
import { TextureLoader } from "three";

const filePath = require.context("../img", false);

export function useTexture(uri: string) {
  const input = getValidInput(uri);
  const texture = useLoader(TextureLoader, input);
  return [texture as Texture | Texture[]];
}

function getValidInput(uri: string) {
  if (!isImage(uri)) return filePath(DEFAULT_IMAGE);
  const isStatic = uri.split("/")[1] === "static";
  const input = !isStatic ? filePath(uri) : uri;
  return input;
}

// Source: https://stackoverflow.com/a/7977314
function isImage(filename: string) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
      //etc
      return true;
  }
  return false;
}

