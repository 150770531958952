import { useEffect, useRef } from "react";
import { Color, Vector2 } from "three";

type Props = {
  position: [x: number, y: number, z: number];
  scale: [x: number, y: number, z: number];
  opacity?: number;
};

export const GradientCircle = ({ position, scale, opacity }: Props) => {
  const materialRef = useRef<any>();

  useEffect(() => {
    if (!materialRef.current?.uniforms) return;
    materialRef.current.uniforms.opacity.value = opacity;
  }, [opacity]);
  return (
    <mesh position={position} scale={scale}>
      <planeGeometry />
      <shaderMaterial
        ref={materialRef}
        attach="material"
        args={[RadialGradientShader]}
        transparent
      />
    </mesh>
  );
};

type RadialGradient = {
  center: Vector2;
  radius: number;
  innerColor: Color;
  outerColor: Color;
  opacity: number;
};

const defaultGradient: RadialGradient = {
  center: new Vector2(0.5, 0.5),
  radius: 0.05,
  innerColor: new Color("black"),
  outerColor: new Color("#220044"),
  opacity: 0.75,
};

const RadialGradientShader = {
  uniforms: {
    center: { value: defaultGradient.center },
    radius: { value: defaultGradient.radius },
    innerColor: { value: defaultGradient.innerColor },
    outerColor: { value: defaultGradient.outerColor },
    opacity: { value: defaultGradient.opacity }, // Opacity uniform
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    varying vec2 vUv;
    uniform vec2 center;
    uniform float radius;
    uniform float opacity; 
    uniform vec3 innerColor;
    uniform vec3 outerColor;
    void main() {      
      float distance = distance(vUv, center);
      float gradient = smoothstep(radius, radius + 0.01, distance);
      vec4 finalColor = mix(vec4(innerColor, opacity), vec4(outerColor, opacity), gradient);
      finalColor.a *= opacity; // Multiply alpha by opacity value
      gl_FragColor = finalColor;
    }
  `,
};
