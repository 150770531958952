import { IMetaData } from "Interfaces";

export const DEFAULT_IMAGE = "./youspinmerightroundbabyrightround.png";
export const DEFAULT_VIDEO = "./animateVR.mp4";
export const DEFAULT_FONT_TYPE = "/Inter-Bold.woff";

export const VIDEO_METADATA: IMetaData[] = [
  { url: "https://battlerock.itch.io/animatevr" },
  { url: "https://battlerock.itch.io/battlerocktopia" },
  { url: "https://battlerock.itch.io/boozoo" },
  { url: "https://www.youtube.com/watch?v=svHzOCP5HTc" },
  { url: "https://battlerock.itch.io/doomscroller", isNSFW: true },
  { url: "https://battlerock.itch.io/doomscroller-vr", isNSFW: true },
  { url: "https://battlerock.itch.io/harpoona-matata" },
  { url: "https://battlerock.itch.io/humptydumpty" },
  { url: "https://ldjam.com/events/ludum-dare/43/imposter-monster" },
  { url: "https://battlerock.itch.io/mobster-lobsters" },

  { url: "https://www.youtube.com/watch?v=i0ZM0eg-VhU" },
  {
    url: "https://play.google.com/store/apps/details?id=com.trocktopia.shadowtopia",
  },
  { url: "https://battlerock.itch.io/tale-of-swords" },
  {
    url: "https://play.google.com/store/apps/details?id=com.Trocktopia.Taptopia",
  },
  { url: "https://battlerock.itch.io/thug-slugs" },
  { url: "https://battlerock.itch.io/voxel-garden" },
  { url: "https://store.steampowered.com/app/1285860/Voxelotl_Garden/" },
];
